<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :isLoading="isLoading"
  :actions="actions"
  :querySearch="getNostrificationStatement")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
export default {
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$t('tableHeaders.created_at') },
        { value: 'number', text: this.$t('tableHeaders.number'), sortable: false },
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailorFullName', text: this.$t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'sailorFullNameEng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'manager.id', text: this.$t('tableHeaders.manager_id'), sortable: false },
        { value: 'manager.full_name', text: this.$t('tableHeaders.manager_full_name'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_statement') },
        { value: 'nostrification_document_status', text: this.$t('tableHeaders.status_document') },
        { value: 'event', text: this.$t('actions'), sortable: false, align: 'center' }
      ],
      actions: [
        {
          id: 1,
          name: 'mdi-arrow-right',
          to: ({ id }) => id && ({ name: 'nostrification-statement', params: { id }, query: { ...this.$route.query } }),
          color: 'blue',
          tooltip: 'documentIconControl.go'
        }
      ],
      radioListItem: [
        {
          id: 1,
          label: this.$t('all'),
          value: 'getNostrificationStatement',
          action: () => this.getNostrificationStatement({ page: 1, page_size: 10 }) },
        {
          id: 2,
          label: this.$t('waitPaidTranslator'),
          value: 'getWaitPaidTranslationNostrificationStatement',
          action: () => this.getWaitPaidTranslationNostrificationStatement({ page: 1, page_size: 10 }) },
        {
          id: 3,
          label: this.$t('waitPaidMES'),
          value: 'getWaitPaidMESNostrificationStatement',
          action: () => this.getWaitPaidMESNostrificationStatement({ page: 1, page_size: 10 }) }
      ],
      radioValue: 'getNostrificationStatement'
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.statement.nostrificationStatement,
      isLoading: (state) => state.statement.isLoading
    })
  },
  watch: {
    radioValue (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this[newVal]({ page: 1, page_size: 10 })
    }
  },
  beforeMount () {
    this.$store.commit('setActivePage', { name: 'nostrification', access: checkAccess('menuItem-nostrification') })
  },
  methods: {
    ...mapActions(['getNostrificationStatement', 'getWaitPaidTranslationNostrificationStatement', 'getWaitPaidMESNostrificationStatement'])
  }
}
</script>
